import React from "react";
import {
  FaFacebook,
  FaFacebookF,
  FaInstagram,
  FaLinkedin,
  FaLinkedinIn,
  FaPlay,
  FaXTwitter,
  FaYoutube,
} from "react-icons/fa6";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer dir="rtl" class="bg-black">
      <div class="container-lg  py-6 pt-5 pb-5">
        <div class="row gap-[16px] lg:gap-0">
          <div class="col-lg-3">
            <div class="d-flex justify-content-start">
              <img class="w-25" src="/logo.png" alt="xr-logo-white" />
            </div>
            <p class="mt-4 text-white font-weight-light">
              Xperience Realty مفتخر است که خدمات، مشاوره، پشتیبانی و کمک به
              مشتریان محلی و خارج از کشور خود را با بالاترین سطح ممکن از خدمات،
              مشاوره، پشتیبانی و کمک در مورد تمامی نیازهای ملکی آنها ارائه می
              کند.
            </p>
          </div>
          <div class="col-lg-3 text-right">
            <p class="font-weight-bold text-white">پروژه سرمایه گذاری</p>
            <ul style={{ listStyle: "none" }} class="mt-2 text-white p-0">
              <li>
                <Link class="text-white font-weight-light" to="/projects/EMAAR">
                  Emaar
                </Link>
              </li>
              <li>
                <Link
                  class="text-white font-weight-light"
                  to="/projects/NAKHEEL"
                >
                  Nakheel
                </Link>
              </li>
              <li>
                <Link class="text-white font-weight-light" to="/projects/DAMAC">
                  Damac
                </Link>
              </li>
              <li>
                <Link
                  class="text-white font-weight-light"
                  to="/projects/MERAAS"
                >
                  Meraas
                </Link>
              </li>
              <li>
                <Link
                  class="text-white font-weight-light"
                  to="/projects/DUBAI-PROPERTIES"
                >
                  Dubai Properties
                </Link>
              </li>
              <li>
                <Link class="text-white font-weight-light" to="/projects/SOBHA">
                  Sobha
                </Link>
              </li>
            </ul>
          </div>
          <div class="col-lg-3 text-right">
            <p class="font-weight-bold text-white">ارتباط</p>
            <ul style={{ listStyle: "none" }} class="mt-2 text-white p-0">
              <li>
                <a
                  href="https://www.google.com/maps/place/Xperience%E2%80%8B+Realty/@25.1143597,55.2437603,17z/data=!3m1!4b1!4m6!3m5!1s0x3e5f69742ae364c3:0xf5f30b07708195fd!8m2!3d25.1143549!4d55.2463352!16s%2Fg%2F11ty7cwknc?entry=tts&amp;shorturl=1"
                  target="_blank"
                  rel="noreferrer"
                  class="text-white font-weight-light"
                >
                  دفتر شماره، 301 و 302، سطح 3، ساختمان 1، پارک هایتس اسکوئر 1،
                  دبی هیلز استیت، دبی، امارات متحده عربی صندوق پستی: 65777
                </a>
              </li>
            </ul>
          </div>
          <div class="col-lg-3 text-right">
            <p class="font-weight-bold text-white">فضای مجازی</p>
            <div class="mt-2 d-flex gap-2 ">
              <Link>
                <FaYoutube size={32} color="#fff" />
              </Link>
              <Link>
                <FaFacebook size={32} color="#fff" />
              </Link>
              <Link>
                <FaLinkedin size={32} color="#fff" />
              </Link>
              <Link className="">
                <FaInstagram size={32} color="#fff" />
              </Link>
              <Link className="">
                <FaXTwitter size={32} color="#fff" />
              </Link>
            </div>
          </div>
        </div>
       
      </div>
      <div class="border-top text-black p-2 bg-white">
          <div class="  text-center gap-3 lg:gap-7 flex items-center flex-wrap lg:pb-0 justify-center sm:justify-around">
            <p class="text-center"> @2024 تمامی حقوق محفوظ است</p>
            <a
              href="https://sirir.ir/"
              target="_blank"
              rel="noreferrer"
              dir="ltr"
              className="flex gap-1 items-center text-black"
            >
              Powered By{" "}
              <img
                src="https://sirir.ir/logo.png"
                className="w-8 object-contain items-center mb-1"
                alt="Sirir logo"
              />
              Sirir
            </a>
          </div>
        </div>
    </footer>
  );
};

export default Footer;
